<template>
  <div>
    <div class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div
          class="content has-text-grey has-padding-x-50 has-margin-bottom-50"
        >
          <h2>Monthly reports</h2>
          <p>
            For all subscribed sites we generate a monthly report detailing the
            previous month's tasks, monitoring stats, backup activity and more.
          </p>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns is-multiline">
          <div class="column is-12">
            <collapse title="Reports">
              <reports-table :site-id="siteId" />
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteReports",
  components: {
    "reports-table": () => import("@shared/sites/report/_reportsTable")
  },
  data() {
    return {
      siteId: this.$route.params.siteId
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    }
  },
  beforeMount() {
    if (!this.site.isSubscribed) {
      this.$router.push(`/sites/${this.siteId}`);
      this.$toast.open({
        message: "You don't have permission to access this content",
        type: "is-danger"
      });
    }
  }
};
</script>

<style></style>
